import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initalLoad } from '../../../action/homeAction'

const ReactDOM = require('react-dom')
// const ReactMarkdown = require('react-markdown')
const ReactMarkdown = require('react-markdown/with-html')


class Marketpage extends Component {

    constructor(props){
        super(props)
        this.state = {
            markupText:''
        }
    }

    componentWillMount(){
        console.log(this.props.location)
        this.props.initalLoad(this.props.location);
    }

    componentWillReceiveProps(nextProps){
        console.log(nextProps.home)
        this.setState({markupText:nextProps.home});
    }

     render() {
       return <span><br/><br/><br/>
                <div className="row" style={{background:"white"}}>
                    <div className="col col-12" style={{textAlign:'left'}}>
                        <ReactMarkdown source={this.state.markupText}  escapeHtml={false}/>
                    </div>
                </div>
            <br/><br/>
           </span>       
     }
}

function mapStateToProps(state){
    return {home:state.home};
  }


export default connect(mapStateToProps,{initalLoad})(withRouter(Marketpage));
