import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import utils from '../utils/utils'

class Menubar extends Component {
    constructor(){
        super()
        this.state = {
            activeLabel: '',
            navLeaf:[
                {   
                    index:0,
                    label:'',
                    link:'/'
                }
            ]
        }
    }
    
    componentDidMount = ()=> {
        // this._asyncRequest = fetchMenu().then(
        //     externalData => {
        //       this._asyncRequest = null;
        //       //console.log(externalData.data)
        //       this.setState(externalData.data);
        //     }
        //   );
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
          this._asyncRequest.cancel();
        }
      }

    componentWillReceiveProps = nextProps => {
        this.setState(nextProps.common);
      }

    render(){
        var menu = new utils().isMenuBarEnabled(this.props.location);
        return(
            menu &&
            <div className="row" style={{ boxShadow: '0 4px 8px 0 rgba(28,32,36,.2)' , background:'#e9c3bc'}}>
                <div className="col col-6 col-lg-1 offset-lg-1 no-left-padding">
                    <a className="nav-link" style={{paddingTop:'1rem', color:'rgba(0,0,0,.5)', textAlign:'start'}}></a>
                </div>
                <div className="col col-6 col-lg-2">
                    {/* <a className="nav-link" style={{paddingTop:'1rem', color:'rgba(0,0,0,.5)', textAlign:'end'}} href="/ot">Order Tracking</a> */}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {common:state.common};
}

export default withRouter(connect(mapStateToProps, {})(Menubar));
