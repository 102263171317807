import React, {Component}from 'react';

class Siteheader extends Component {
     render() {
       return(
          <div>
               <div className="row" style={{background: '#cb9892'}}>
               <div className="col-sm" style={{height:'10px'}}>
                    .
               </div>
               </div>
          </div>
          
       )
     }
}
export default Siteheader;