import React, {Component}from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initalLoad } from '../../../action/homeAction'


class Homepage extends Component {

    constructor(props){
        super(props)
        this.state = {
            slots:[
            ]
        }
    }

    componentWillMount(){
        // this.props.initalLoad();
    }

    componentWillReceiveProps(nextProps){
        // this.setState(nextProps.home);
    }

     render() {
       return <span><br/>
                <div className="row" style={{background:"white", alignItems: "center", display: "flex"}}>
                  <div class="col-sm-12 col-md-12">       
                        <img src="/img/bnr_2.jpeg" alt="" style={{width:'100%', maxHeight:'700px', maxWidth:'700px'}}/>
                    </div>
                </div>
            <br/><br/>
           </span>       
     }
}

function mapStateToProps(state){
    return {home:state.home};
  }


export default connect(mapStateToProps,{initalLoad})(withRouter(Homepage));
