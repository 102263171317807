import axios from 'axios'
import { HOME_INIT_REQUEST } from './'

export const initalLoad = (path) => dispatch => {
    var url  = ''
    console.log(path.pathname)
    if(path.pathname==='/m/refund'){
        url = '/api/m/page/rrp';
    }if(path.pathname==='/m/tnc'){
        url = '/api/m/page/tnc';
    }if(path.pathname==='/m/ppolicy'){
        url = '/api/m/page/ppolicy';
    }
    console.log(url)
    axios.get(url)
    .then( res =>
        dispatch({
            type: HOME_INIT_REQUEST,
            payload: res.data
            })
        )
    .catch( err => {
        console.error(err);
        return dispatch({
            type: HOME_INIT_REQUEST,
            payload: {}
        })
    }
 );
}
